
const Components = require('components');
const Utils = require('utils');

const headerimg = require("theme/images/header-img.png").default;

const components_helloimaaron = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      setView: (data) => actions.setView(data),
    };
  },

  componentInt: (state, handlers) => {
    const inview = Utils.inviewport(document.getElementById('home'));
    if (inview) handlers.setView({ in: true })
  },
  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {

    return (
      <header className="components_helloimaaron" id="home">
        <div className="container">
          <div className="row">
            <br />
            <br />
            <br />
            <br />
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <h1 className={`scrollimation fade-down d1 ${state.view.in && 'in'}`}>Hello, I am Aaron Colby</h1>
              <h2 className={`scrollimation fade-down d3 ${state.view.in && 'in'}`}><span className="main-color">E</span>xperienced, <span className="main-color">F</span>ull stack, <span className="main-color">S</span>oftware <span className="main-color">E</span>ngineer</h2>
            </div>
          </div>
        </div>
      </header>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_helloimaaron }, require('./index.scss'));
