
function db(namespace = 'default') {

  return {
    init,
    retrieve,
    set,
    patch,
    connect,
    close,
    watch,
    unwatch,
    wait,
  };

  function init(opts) {

    (_global()).PERSIST_SESSION[namespace].db = {
      db: opts.db,
      auth: opts.auth,
      // old `ws://${_state().host}/.ws?v=5&ns=${_state().ns}`
      dbWS: `${opts.db.replace('http', 'ws').split('?ns=')[0]}/.ws?v=5${(opts.db.indexOf('?ns=') > -1) && `&ns=${opts.db.split('?ns=')[1]}` || ''}`,

      // internal state
      _ws: null, // Reference to the Websocket used.
      _sendQueue: [],
      _sendQueueTimeOut: null,

      _connected: false, // the instance is connected
      _reconnecting: false, // timeout of the reonnecting state
      _keepalive: null, // interval for re-establising a connection
      _watchers: pathstowatchobj(), // Pathed object containing all calbacks at a particular path The paths and their corresponding callbacks

      _frameCount: 0, // the number of frame that make up the current message
      _received: 0, // the nubmbe of frames that have been received
      _buf: '', // the current contents of the incoming message

      _req: 0, // the total number of requests that have been made
      _query: 0, // the total number of query requests made
      _onResponseHandlers: {}, // keeps track of what to do when the api responds with a success

      // managing retrieve
      _retrieved: {}, // the latest retrieved data for a given path
      _retrievedRefs: {}, // cleanup old retrieved data
    };

  }

  function _state() {
    return (_global()).PERSIST_SESSION[namespace].db || {};
  }

  function _connect() {

    _state()._ws = new WebSocket(_state().dbWS);
    _state()._ws.onclose = () => {
      _state()._connected = false;
      if (!_state()._redirecting) {
        clearInterval(_state()._keepalive);
        // setTimeout(_connect, 5000);
      }
    };

    _state()._ws.onopen = () => {};

    _state()._ws.onerror = (err) => {
      close();
      clearTimeout(_state()._reconnecting);
      _state()._reconnecting = setTimeout(_connect, 5000);
    };

    _state()._ws.onmessage = (msg) => {
      const frame = msg.data;
      if (!isNaN(frame)) {
         // if it's a number we need to hold on to it, see _frameCount, _received, _buf
        _state()._frameCount = parseInt(frame, 10);
        _state()._received = 0;
        _state()._buf = '';
      } else if (_state()._frameCount) {
        if (_state()._received <= _state()._frameCount) {
          _state()._received += 1;
          _state()._buf += frame;
        }
        if (_state()._received === _state()._frameCount) {
          handleMessage(JSON.parse(_state()._buf));
          _state()._frameCount = 0;
          _state()._buf = '';
        }
      } else {
        handleMessage(JSON.parse(frame));
      }
    };

  }

  function close() {
    _state()._connected = false;
    clearInterval(_state()._keepalive);
    _state()._ws.close();
  }

  function _send(msg, queueonly = false) {
    if (!queueonly) _state()._sendQueue.push(msg);
    if (!_state()._connected) {
      if (_state()._sendQueueTimeout) return;
      _state()._sendQueueTimeout = setTimeout(() => {
        _state()._sendQueueTimeout = null;
        _send('', true);
      }, 300)
    };
    while (_state()._sendQueue.length) {
      const toSend = _state()._sendQueue.shift();
      try {
        _state()._ws.send(toSend);
      } catch(e) {
        console.log('trying to send when not connected')
      }
    }
  }

  function sendKeepalive() {
    _send('0');
  }

  function send(msg, cb) {
    if (cb) {
      _state()._req += 1;
      msg.d.r = _state()._req;
      _state()._onResponseHandlers[_state()._req] = cb;
    }
    const content = JSON.stringify(msg);
    if (content.length > 32000) {
      const chunks = _chunkString(content, 32000);
      _send(chunks.length);
      chunks.forEach((chunk) => {
        _send(chunk);
      })
    } else {
      _send(content);
    }
    return cb && new Promise((resolve, reject) => { cb.promise = { resolve, reject }; }) || Promise.resolve();
  }

  function onResponse(res) {
    const cb = _state()._onResponseHandlers[res.d.r] || false;
    if (res.d.b.s !== 'ok') {
      console.log('PERSIST here', res.d.b.s, res.d.r)
      // TODO consider how to handle errors i.e. permission denied
      // Will end up here if there is a rules violation
      /* TODO handle errors */ 
    }
    if (cb) {
      cb(res);
      cb.promise.resolve();
      delete _state()._onResponseHandlers[res.d.r];
    }
  }

  function handleMessage(msg) {
    switch (msg.t) {
      case 'c': // control message
        switch (msg.d.t) {
          case 'r': // RESET - redirect to different server
            // TODO consider this... new host msg.d.d
            _state()._redirecting = true;
            close();
            // return _connect();
          case 'h': // HELLO - should be first message recieved
            return send({ t: 'd', d: { a: 's', b: { c: { 'firewatch-2-0-2': 1 } } } },
              (res) => {
                _state()._keepalive = setInterval(sendKeepalive, 30000);
                if (_state().auth) send({ t: 'c', d: { t: 'p', d: {} } });
                if (!_state().auth) _state()._connected = true;
              }
            );
          case 'o': // PONG - Handshank for req 1
            return send({ t: 'd', d: { a: 'auth', b: { cred: _state().auth } } },
              (res) => {
                _state()._connected = true;
              }
            );
          case 'e': // ERROR

            break;
          case 's': // SHUTDOWN
            close();
            break;
          default:
            break;
        }
        break;
      case 'd': // data message
        if (msg.d.b) _state()._retrieved[msg.d.b.p] = msg.d.b.d;
        if (msg.d.a === 'd' || msg.d.a === 'm') _state()._watchers.get(msg.d.b.p, msg.d.b.d).forEach(cb => cb());
        if (msg.d.r) onResponse(msg);
        break;
      default:
        break;
    }
  }

  function watch(path, cb, options) {
    _state()._watchers.set(path, cb);

    const msg = { t: 'd', d: { a: 'q', b: { p: path, h: '' } } };
    if (options) { 
      _state()._query += 1;
      msg.t.d.b.t = _state()._query;
      if (options.parameterValue !== undefined) msg.t.d.b.q.eq = options.parameterValue;
      if (options.start !== undefined) msg.t.d.b.q.sp = options.start;
      if (options.end !== undefined) msg.t.d.b.q.ep = options.end;
      if (options.limitToFirst || options.limitToLast) msg.t.d.b.q.l = options.limitToFirst || options.limitToLast;
      if (options.limitToLast) msg.t.d.b.q.vf = 'r';
    }
    return send(msg, (rep) => {});
  }

  function unwatch(path) {
    _state()._watchers.remove(path);
    send({ t: 'd', d: { a: 'n', b: { p: path } } }, (res) => {});
  }

  function connect() {
    _connect();
    return _waitUntil(() => _state()._connected === true, () => {});
  }

  function wait(path, condition, timeout = 10000) {
    return new Promise((resolve) => {
      watch(path, (item) => {
        if (condition(item)) {
          unwatch(path);
          resolve(item)
        }
      })

    })
  }

  function retrieve(path = '') {
    const location = path.split('/').filter(item => item).join('/');
    _state()._retrievedRefs[location] = (_state()._retrievedRefs[location] || 0) + 1;
    return new Promise((resolve) => {
      send({ t: 'd', d: { a: 'q', b: { p: location, h: ''} } }, (msg) => {
        resolve(_state()._retrieved[location] !== undefined ? _state()._retrieved[location] : null);
        _state()._retrievedRefs[location] = (_state()._retrievedRefs[location] || 0) - 1;
        if (_state()._retrievedRefs[location] <= 0) delete _state()._retrieved[location];
      })
    })
  }

  function set(path = '', data) {
    const location = path.split('/').filter(item => item).join('/');
    return new Promise((resolve) => {
      send({ t:'d', d: { a: 'p', b: { p: location, d: data } } }, resolve)
    })
  }

  function patch(path = '', data) {
    const location = path.split('/').filter(item => item).join('/');
    return new Promise((resolve) => {
      send({ t:'d', d: { a: 'm', b: { p: location, d: data } } }, resolve)
    })
  }

  // Helpers -> {
  function pathstowatchobj() {

    const _pathsToWatch = {}; // keeps track of the structure of the object
    const _callbacks = {}; // keeps track of the actual callbacks

    // set a callback at a specific path
    function set(path, callback) {
      const sanitized = _sanitizePath(path);

      _callbacks[`/${sanitized}`] = _callbacks[`/${sanitized}`] || [];
      _callbacks[`/${sanitized}`].push(callback);
      addPathDeep(_pathsToWatch, sanitized.split('/'));

      function addPathDeep(against = {}, params = []) {
        if (params.length === 0) return;
        const key = params[0];
        against[key] = against[key] || {};
        return addPathDeep(against[key], params.slice(1, 17));
      }

    }

    // remove a calback at a specific path
    function remove(path) {
      const sanitized = _sanitizePath(path);
      delete _callbacks[`/${sanitized}`];
      removePathDeep(_pathsToWatch, path.split('/'));

      function removePathDeep(against = {}, params = []) {
        if (params.length === 1) {
          delete against[params[0]];
          return;
        }
        return removePathDeep(params[0], params.slice(1, 17));
      }
    }

    // get the callbacks at a spcific path
    function get(prefix, data) {

      function _clean(toclean) {
        if (!toclean || typeof toclean !== 'object') return toclean;

        return Object.keys(toclean).reduce((acc, key) => {
          if (key.indexOf('/') >= 0) {
            acc[key.split('/')[0]] = _clean({ ...(acc[key.split('/')[0]] || {}), [`${key.split('/').slice(1,5).join('/')}`]: toclean[key] });
          } else {
            acc[key] = toclean[key];
          }
          return acc;
        }, {});

      }

      return checkForCallbacks(_pathsToWatch, addPrefixToData(_sanitizePath(prefix).split('/'), _clean(data)), '');

      function addPrefixToData(params = [], fulldata = {}) {
        if (!params.length) return fulldata;
        return addPrefixToData(params.slice(0, -1), { [params[params.length - 1]]: fulldata });
      }

      function checkForCallbacks(watchObj = {}, snippit = {}, curPrefix = '', cbs = []) {

        Object.keys(watchObj)
        .forEach((key) => {
          if (snippit[key] === undefined) return cbs;

          const dataAtKey = snippit[key];
          const calbacksAtKey = _callbacks[`${curPrefix}/${key}`];

          if (calbacksAtKey && calbacksAtKey.length) calbacksAtKey.forEach((calbackAtKey) => {

            return cbs.push(() => {
              if (typeof calbackAtKey !== 'function') return;
              return calbackAtKey(snippit[key])
            })
          });
          if (dataAtKey === null || typeof dataAtKey !== 'object') return cbs;
          return checkForCallbacks(watchObj[key], snippit[key], `${curPrefix}/${key}`, cbs);
        });
        return cbs;
      }
    }

    return {
      set,
      remove,
      get,
    };

    function _sanitizePath(path) {
      return `/${path}`.replace('//', '/').replace('//', '/').replace('//', '/').replace('/', '');
    }

  }

  function _waitUntil(resolvesOn, rejectsOn) {
    return new Promise((resolve, reject) => {
      const toClear = setInterval(() => {
        if (resolvesOn()) {
          resolve();
          clearInterval(toClear);
        } else if (rejectsOn()) {
          reject();
          clearInterval(toClear);
        }
      }, 30);
    });
  }

}

module.exports = db;

function _global() {
  try {
     return !!window && window || global;
   } catch (e) {
     return global;
   }
}

function _chunkString(str, size) {

  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks

}
