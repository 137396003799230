
const debug = '';

function WrappedApi(Api) {
  const name = Object.keys(Api)[0];

  const api = apiClient();

  return Api[name]({ api });
}

module.exports = WrappedApi;

function apiClient() {

  // TODO handle errors ------ 
  // TODO get autorization information required errors ------

  function client(method, path, data = {}) {
    const url = (window.APPLICATIONCERT && window.APPLICATIONCERT.url_api) || 'http://localhost:3005';
    return fetch(`${url}${path}`, _noUndefined({
      method: method.toUpperCase(),
      body: data.body && JSON.stringify(data.body || {}) || undefined,
      headers: {'Content-Type': 'application/json', 'connection': 'keep-alive'},
    }))
    .then((response) => {
      return response.json()
    })
    .then((res) => {
      if (res.status === 'error') throw { ...res.error, apiError: true };
      return res.data;
    })
  }

  return client;
}

function _noUndefined(obj) {
  return Object.keys(obj)
    .reduce((acc, curr) => {
      if (obj[curr] === undefined) return acc;
      return { ...acc, [curr]: obj[curr] }
    }, {})
}