
const Components = require('components');
const Utils = require('utils');

const components_sections_ideas = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  
  handlers: (state, actions) => {
    return {
      setView: (data) => actions.setView(data),
    };
  },

  componentInt: (state, handlers) => {
    const inview = Utils.inviewport(document.getElementById('ideas'));
    if (inview) handlers.setView({ in: true })
  },
  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <section id="ideas" className="bg-main-color cta components_sections_ideas">
        <div className={`container text-center scrollimation fade-up ${state.view.in && 'in'}`}>
          <p>It's not about ideas. It's about making ideas happen.</p>
          <a className="btn btn-dc btn-white scrollto" 
            onClick={() => { Utils.scrollto('contact') } }
          >Contact Me</a>
        </div>
      </section>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_sections_ideas }, require('./index.scss'));
