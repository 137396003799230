const Utils = require('src/utils');

function utils_inviewport(el) {
  if (!el) return false;
  const rect = el.getBoundingClientRect();

  return rect.y <= (window.innerHeight || document.documentElement.clientHeight);
}

module.exports = utils_inviewport;
