
const Components = require('components');
const html2pdf = require('html2pdf.js');

const components_layouts_main = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {
      setRoute: [state => Stores.router.setRoute],
    };
  },
  mapStore: (Stores) => {
    return {
      route: [props => Stores.router.data.route, 'landing'],
      routes: [props => Stores.app.data.router.routes, []],
      modal: [state => Stores.router.data.modal, {}],
    }; 
  },

  handlers: (state, actions) => {
    return {
      setView: actions.setView,
      setRoute: actions.setRoute,
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {

    const subroute = state.store.route.split('::')[1];
    const route = state.store.route.split('::')[0];

    if (route !== state.view.prevroute && !subroute) {
      const el = document.getElementById('body-contents')
      if(el) el.scroll({ top: 0 || 0 })
    }

    if (subroute) {
      setTimeout(() => {
        const el = document.getElementById(subroute);
        document.getElementById('body-contents').scroll({ top: el && (el.offsetTop - 100) || 0, behavior: 'smooth' })
      });
    }

    handlers.setRoute(route);
    handlers.setView({ prevroute: route})
    
    const compName = (state.store.routes.find(item => item.name.split('::')[0] === state.store.route) || {}).comp || 'Components.routes.main';
    const compPath = compName.split('.').slice(1, 10).join('.');
    const Comp = _resolve(Components, compPath);

    return {
      compName,
      Comp,
    };
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {
    prevroute: '',
    showresume: false,
    downloading: false,
  }, // default view state

  render: (state, handlers) => {

    const Comp = _resolve(Components, (state.store.modal && state.store.modal.component || 'Components.DOESNOTEXIST').split('Components.')[1]);
    const Modal = { Comp };

    return (
      <div className="components_layouts_main">
        <div className="scrollbody" id="page-contents">
          <Components.nav
            onClick={(item) => {
              if (item === 'resume') handlers.setView({ showresume: true });
            }}
          />
          <div className="robo-rel" id="body-contents">
            <state.derived.Comp />
          </div>
          <div className="robo-abs zdown">
            <Components.helloimaaron />
          </div>
        </div>
        {
          <div className={`robo-abs resume-overlay fadeinout ${state.view.showresume && 'in' || 'out'}`}>
            <div className="robo-rel" style={{ overflow: 'scroll' }}>
              <Components.resume />
            </div>
            
            <div className="close-button left"
              style={{ background: state.view.downloading && '#E9E6E6' || 'white' }}
              onClick={() => {
                if (state.view.downloading) return;

                handlers.setView({ downloading: true })
                var element = document.getElementById('resume');

                var opt = {
                  margin:       0,
                  filename:     'acolby_resume.pdf',
                  image:        { type: 'jpeg', quality: 1 },
                  html2canvas:  { scale: 2, width: 2400, height: 4300 },
                  jsPDF:        { unit: 'in', orientation: 'portrait' }
                };
                 
                // New Promise-based usage:
                html2pdf().set(opt).from(element).save().then(() => {
                  setTimeout(() => {
                    handlers.setView({ downloading: false })
                  }, 400)
                });
              }}
            >
              <div className="robo-center">
                <i className={`fa fa-download`} />
              </div>
            </div>
            <div className="close-button right"
              onClick={() => {
                handlers.setView({ showresume: false });
              }}
            >
              <div className="robo-center">
                <i className={`fa fa-close`} />
              </div>
            </div>

          </div>
        }
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_layouts_main }, require('./index.scss'));
