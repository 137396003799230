const Components = require('src/components');

function apps_site() {

  const entry = Components.apps.robo;

  const config = {
    root: 'Components.apps.robo',
    layout: 'Components.layout.main', // what layout to 
    theme: {
      // overwrite theme CSS Variables
    },
    head: {
      title: 'RoboBrowser', // <title>[ CONTENTS ]</title>
      favicon: '', //  <link rel="shortcut icon" href="[ CONTENTS ]" />
      metas: [
        { charSet: 'utf-8' },
        { name: 'viewport', content: 'viewportcontentwidth=device-width, initial-scale=1, shrink-to-fit=no' },
        { name: 'description', content: 'RoboBrowser - No Code Browser Automation' },
      ]
    },
    router: {
      noAuthRoutes: [],
      queryParams: [],
      persistantQueryParams: [],
      groups: {},
      categories: {},
      routes: [
        {
          name: 'main',
          displayName: 'main',
          path: '/',
          comp: 'Components.routes.main',
        },
      ]
    },
    store: {
      // conrigure the initial store
    },
  };

  return {
    entry,
    config,
  }

}

module.exports = apps_site;
