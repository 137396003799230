
const Components = require('components');
const Utils = require('utils');

const components_sections_portfolio = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      setView: (data) => actions.setView(data)
    };
  },

  componentInt: (state, handlers) => {
    const inview = Utils.inviewport(document.getElementById('portfolio'));
    if (inview) handlers.setView({ in: true })
  },
  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <section id="portfolio" className="components_sections_portfolio">
        <div className="container p-3">
          <br />
          <br />
          <br />
          <br />
          <h1 className={`section-title scrollimation fade-up ${state.view.in && 'in'}`}>Experience</h1>
          <br />
          <div className="row">
            <div className={`col-xs-6 scrollimation fade-right ${state.view.in && 'in'}`}>
              <Components.misc.portfoliocard 
                img={'images/payClearlyScreen.png'}
                href={'https://www.payclearly.com/'}
                title={'Pay Clearly'}
                desc={'Platform for managing automated vendor payments'}
              />
            </div>
            <div className={`col-xs-6 scrollimation fade-left ${state.view.in && 'in'}`}>
              <Components.misc.portfoliocard 
                img={'images/launchScreen.png'}
                href={'https://www.nike.com/launch'}
                title={'Nike/Launch'}
                desc={'Nike\'s web experience for dropping their most in demand stock'}
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="row">
            <div className="col">
              &nbsp;
            </div>
            <div className={`col-xs-6 scrollimation fade-right ${state.view.in && 'in'}`}>
              <Components.misc.portfoliocard 
                img={'images/minapsysScreen.png'}
                href={'http://www.minapsys.com/'}
                title={'Minapsys'}
                desc={'Staged asynchronous collaboration service aimed at streamlining group communication'}
              />
            </div>
            <div className={`col-xs-6 scrollimation fade-left ${state.view.in && 'in'}`}>
              <Components.misc.portfoliocard 
                img={'images/chattunnelScreen.png'}
                href={'http://splash.chattunnel.com/#/'}
                title={'Chattunnel'}
                desc={'End-to-end encrypted chat application'}
              />
            </div>
          </div>
        </div>
      </section>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_sections_portfolio }, require('./index.scss'));
