
const debug = '';

/*
window.storeActionQueue = window.storeActionQueue || [];
window.storeActionQueueInterval = window.storeActionQueueInterval || setInterval(() => {
    if (!window.storeActionQueue.length) return;
    const toRun = window.storeActionQueue;
    window.storeActionQueue = [];
    window.APPLICATIONSTORE.dispatch({ type: 'BATCH', meta: { batch: true }, payload: toRun })
  }, 1);
*/

function WrappedStore(duck) {
  const { actions, reducer, namespace, defaultState } = duck;

  return {
    reducer: _wrapReducer(reducer),
    actions: _wrapActions(actions),
  };

  function log(...args) {
    if (debug !== namespace) return;
    console.log('STORE:', ...args)
  }

  function _wrapReducer(reducer) {
    const wrapped = (state, action) => {
      const method = ((action.type.split('::')[0] === namespace) && reducer[`${action.type.split('::')[1]}`]) || (() => state || defaultState);
      return window._patch(state || defaultState, method(state || defaultState, action.data))
    }
    const status = (state = {}, action) => {
      const name = action.type.split('::')[1];
      if ((action.type.split('::')[0] === namespace) && name === 'status') return window._patch(state, action.data);
      if ((action.type.split('::')[0] === namespace) && name === 'clear') return {};
      return state;
    }
    return { data: wrapped, status };
  }

  function _wrapActions(actions) {

    return Object.keys(actions())
      .reduce((acc, actionname) => {
        acc[actionname] = (...args) => {
          return () => {
            return Promise.resolve()
            .then(() => {

              // Makes the 'state' passed to the action a method that returnes the latest state
              const _state = () => {
                return _resolve(_getState(), namespace.replace('stores_', ''), {});
              }
              const currentState = _resolve(_getState(), namespace.replace('stores_', ''), {});
              Object.keys(currentState)
              .forEach((key) => {
                _state[key] = currentState[key];
              });

              const action = actions(_state, _dispatch(), window.APPLICATIONACTIONS, _getState);
              return action[actionname](...args);
            })
            .catch(_catchError)
          }
        }
        return acc;
      }, {})

    function _getState() {
      return window.APPLICATIONSTORE.getState() || {};
    }

    function _dispatch() {
      return (type) => {

        // If this is so that Ducks can dispatch other duck actions
        if (typeof type === 'function') {
          // the type passed was an action 
          return (...params) => {
            return type(...params)();
          }
        }

        return (data) => {
          const action = { type: `${namespace}::${type}`, data };
          log('dispatching', action)
          window.APPLICATIONSTORE.dispatch(action);
        }
      }
    };
  }
}

module.exports = WrappedStore;

function _resolve(object, itemsString, fallback) {
  if (object === undefined || object === null) return fallback;
  if (!(itemsString || '').length) return object;
  const items = itemsString.split('_');
  return window._resolve(object[items[0]], items.slice(1, 10).join('.'), fallback);
};
