window.React = require('react');
window._componentIntervals = window._componentIntervals || {};
const Redux = require('react-redux');

const debug = '';

function Component(Item) {

  // Static component State
  const name = Object.keys(Item)[0];
  const usage = `Components.${name.split('_').slice(1,10).join('.')}`
  const Comp = Item[name];
  
  const log = (...args) => {
    if (debug !== name) return;
    console.log('comp: ', ...args);
  }

  log('--wrapping--');

  class WrappedComponent extends window.React.Component {

    constructor(props) {
      super();
      log('--constructor--', window._servidorrefresing)
      const state = window.APPLICATIONSTATE();
      this.ref = React.createRef();
      this._initialViewState = Comp.view;
      this._derive(props);
      this._sync(props);
    }

    componentDidMount() {
      log('--componentDidMount--');
      if (Comp.componentInt) {
        window._componentIntervals[`${name}_${this.props.id}`] = Comp.componentInt;
        if (!this._compInt) this._compInt = setInterval(() => window._componentIntervals[`${name}_${this.props.id}`](this._state(this.props || {}), this._handlers()), 100)
      }
      if (Comp.remountonservidor || !window._servidorrefresing) {
        if (!Object.keys(this.props['_store_viewState'] || {}).length && Comp.view) {
          _try(() => this.props['_actions_setView'](Comp.view));
        }
        (Comp.componentMounted || (() => {}))(this._state(this.props || {}), this._handlers());
      }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      log('--componentWillReceiveProps--');
      this._derive(nextProps);  
      this._sync(nextProps);
    }

    componentWillUnmount() {
      log('--componentWillUnmount--');
      clearInterval(this._compInt);
      if (window._servidorrefresing) {
        return;
      }
      if (this.props && typeof this.props['_actions_clearView'] === 'function') _try(() => this.props['_actions_clearView']());
      (Comp.componentUnmounted || (() => {}))(this._state(this.props || {}), this._handlers()) || {};
      this._unsync(this.props);
    }

    render() {
      log('--render--');
      return Comp.render(this._state(), this._handlers());
    }

    _derive(props) {
      log('--derive--');
      this.derived = (Comp.componentDerive || (() => {}))(this._state(props || this.props), this._handlers()) || {};
    }

    _sync(props) {
      log('--sync--');
      Object.keys(Comp.mapResources && Comp.mapResources() || {})
        .forEach((key) => {
          if (props && typeof props[`_actions_sync_${key}`] === 'function') props[`_actions_sync_${key}`](this._id(props));
        })
    }

    _unsync(props) {
      log('--unsync--');
      Object.keys(Comp.mapResources && Comp.mapResources() || {})
        .forEach((key) => {
          if (props && typeof props[`_actions_unsync_${key}`] === 'function') props[`_actions_unsync_${key}`](this._id(props));
        })
    }

    _state(props) {

      log((props || this.props || {})['_store_viewState'])
      return {
        store: this._store(props),
        view: (props || this.props || {})['_store_viewState'] || this._initialViewState || {},
        props: props || this.props || {},
        derived: this.derived,
        children: (this.props && this.props.children && ((this.props.children.length && this.props.children) || [this.props.children]) || []).filter(item => item),
        resources: this._resources(props),
        ref: this.ref,
        name,
        id: this._id(),
      };
    }

    _id(props) {
      return (props && props.id) || 'default'
    }

    _resources(props) {
      const obj = Comp.mapResources && Comp.mapResources() || {};
      return _mapFromProps(props || this.props, obj, '_store_resources_')
    }

    _store(props) {
      const obj = Comp.mapStore && Comp.mapStore() || {};
      return _mapFromProps(props || this.props, obj, '_store_')
    }

    _actions(props) {
      const obj = (Comp.mapActions && Comp.mapActions() || {});
      obj.setView = [];
      obj.clearView = [];
      return _mapFromProps(props || this.props, obj, '_actions_', (() => {}))
    }

    _handlers() {
      return Comp.handlers && Comp.handlers(this._state(), this._actions()) || {};
    }

    // componentDidCatch(error, info) {
    //   message = `Error in component ${name}`;
    // }

  }

  
  const mapStateToProps = (state, props) => {

    // Gets called on all state changes
    log('--mapStateToProps--');
    const id = props && props.id || `default`;

    const mappers = Comp.mapStore && Comp.mapStore(state, window.APPLICATIONSELECTORS) || {};

    mappers.viewState = [() => {
      return state.view && state.view.data && state.view.data[usage] && state.view.data[usage][id];
    }];

    Object.keys(Comp.mapResources && Comp.mapResources() || {})
    .forEach((key) => {
      mappers[`resources_${key}`] = [() => {
        return state.resources.data.resources[Comp.mapResources()[key][0](props)];
      }];
    })

    return Object.keys(mappers)
      .reduce((acc, curr) => {
        acc[`_store_${curr}`] = _try(() => mappers[curr][0](props), mappers[curr][1]);
        return acc;
      }, {})

  };

  const mapDispatchToProps = (dispatch, props) => {
    // Gets called only when props change
    log('--mapDispatchToProps--');

    const id = props && props.id || `default`;

    const Actions = window.APPLICATIONACTIONS;
    const mappers = Comp.mapActions && Comp.mapActions(Actions) || {};

    // view actions available to every component
    mappers.setView = [() => {
      return (data) => {
        return Actions.view.set(usage, id, data);
      }
    }]
    mappers.clearView = [() => {
      return (data) => {
        return Actions.view.clear(usage, id);
      }
    }]

    Object.keys(Comp.mapResources && Comp.mapResources() || {})
    .forEach((key) => {
      mappers[`sync_${key}`] = [() => {
        return (data) => {
          const path = Comp.mapResources()[key][0](props);
          return Actions.resources.sync({ id, path });
        }
      }]
      mappers[`unsync_${key}`] = [() => {
        return (data) => {
          const path = Comp.mapResources()[key][0](props);
          return Actions.resources.unsync({ id, path });
        }
      }]
    })

    return Object.keys(mappers)
      .reduce((acc, curr) => {
        acc[`_actions_${curr}`] = (...args) => {
          const action = mappers[curr][0](props)
          if (!action) {
            throw new Error(`${name}:mapStateToActions - ${curr}`)
          }
          log('--dispatch--');
          return dispatch(action(...args));
        };
        return acc;
      }, {})

  };

  return Redux.connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);

}

const _try = (toTry, onErrorReturn) => {
  try {
    const toReturn = toTry();
    if (toReturn === undefined) return onErrorReturn;
    return toReturn;
  } catch (e) {
    return onErrorReturn;
  }
}

const _mapFromProps = (props, obj, prefix, fallback) => {
  return Object.keys(obj)
    .reduce((acc, key) => {
      const val = (props || {})[`${prefix}${key}`];
      acc[key] = val !== undefined ? val : (obj[key][1] || fallback);
      return acc;
    }, {})
}

module.exports = Component;
