
const Components = require('components');
const Utils = require('utils');
const Svgs = require('svgs');

const components_nav = {

  mapActions: (Stores) => {
    return {
      setRoute: [state => Stores.router.setRoute],
    };
  },
  mapStore: (Stores) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      routeClicked: (event, name) => {
        event.preventDefault();
        actions.setRoute(name);
        actions.setView({ show: false })
      },
      setView: actions.setView,
    };
  },

  componentInt: (state, handlers) => {
    const el = document.getElementById("body-contents");
    if (el && el.getBoundingClientRect() && el.getBoundingClientRect().top !== 0) {
      handlers.setView({ scrolled: true });
    } else {
      handlers.setView({ scrolled: false });
    }
  },
  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    clearInterval(state.view.int)
    return {};
  },

  view: {
    show: false,
    int: null,
  }, // default view state

  render: (state, handlers) => {
    return (
      <div id="main-nav" className={`components_nav header navbar navbar-fixed-top ${state.view.scrolled && 'scrolled'}`}>
        <div className="container">
          <div className="navbar-header">
            <button type="button" data-target="#site-nav" className="navbar-toggle"
              onClick={() => handlers.setView({ show: !state.view.show })}
            >
              <span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button><a href="#home" className="navbar-brand">
            </a>
          </div>
          <div id="site-nav" className={`navbar-collapse ${!state.view.show && 'collapse'}`}>
            <ul className="nav navbar-nav navbar-right">
              <li onClick={() => { Utils.scrollto('portfolio') } }>
                <a><i className="fa fa-wrench"></i>Experience</a>
              </li>
              <li
                 onClick={() => {
                   state.props.onClick('resume') }
                 }
              >
                <a href="/#/resume"><i className="fa fa-briefcase"></i>Resume</a>
              </li>
              <li className='robo-cursor-p'
                onClick={() => { Utils.scrollto('contact') } }
              >
                <a><i className="fa fa-envelope"></i>Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_nav }, require('./index.scss'));
