
const Components = require('components');

const components_resume = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      setView: (data) => actions.setView(data)
    };
  },

  componentInt:  (state, handlers) => {
    const el = document.getElementById('resume-containter');
    const zoom = el.getBoundingClientRect().width / 2400;
    handlers.setView({ zoom });
  },
  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {
    zoom: null,
  }, // default view state

  render: (state, handlers) => {
    return (
      <div id='resume-containter' className="robo-rel" style={{ height: '100%', width: '100%', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }}>
        {
          state.view.zoom &&
          <div className="robo-rel" style={{ transform: `scale(${state.view.zoom}) translate(-${(100)}%, -100%)` }}>
            <div id="resume" style={{width: '2400px', height: '4300px', padding: '0px', overflow: 'hidden'}}>
              <div style={{width: '862px', height: '100%', backgroundColor: '#344f6b', float: 'left'}}>
                <Components.resume.left />
              </div>
              <div style={{width: '1538px', height: '100%', backgroundColor: 'white', float: 'right', paddingTop: '150px'}}>
                <Components.resume.right />
              </div>
            </div>
          </div>
        }
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_resume }, require('./index.scss'));
