const extensionToMimeType = require('./extensionToMime');

function storage(namespace = 'default') {

  function init(creds) {
    (_global()).PERSIST_SESSION[namespace].storage = {
      _bucket: creds.bucket,
      _token: creds.auth,
    };
  }

  return {
    init,
    upload: (file, contents) => {
      const state = (_global()).PERSIST_SESSION[namespace].storage;
      return _upload(state._bucket, file, state._token, contents)
    },
    download: (file) => {
      const state = (_global()).PERSIST_SESSION[namespace].storage;
      return _download(state._bucket, file, state._token)
    }
  }

  function _upload(bucket, filename, token, contents) {
    
    const location = `${filename}`.replace('/', '');
    const mimetype = extensionToMimeType(filename.split('.')[1]);
    const boundary = `${Date.now()}`;

    return fetch(`https://firebasestorage.googleapis.com/v0/b/${bucket}/o?name=${encodeURIComponent(location)}`, {
      method: 'POST',
      headers: {
        'content-type': `multipart/related; boundary=${boundary}`,
        'x-firebase-storage-version': 'webjs/8.2.4',
        'x-goog-upload-protocol': 'multipart',
        'authorization': `Firebase ${token}`,
      },
      body: `--${boundary}
Content-Type: application/json; charset=utf-8

{"name":"${location}","contentType":"${mimetype}"}
--${boundary}
Content-Type: ${mimetype}

${contents}
--${boundary}--`
    })
    .then((response) => {
      return response.json()
    })
    .catch(error => {
      console.error(error)
    })
  
  }
  
  function _download(bucket, filename, token) {
    
    const location = `${filename}`.replace('/', '');
    const boundary = `${Date.now()}`;
             
    const base = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${encodeURIComponent(location)}`
    return fetch(`${base}`, {
      method: 'GET',
      headers: {
        'authorization': `Firebase ${token}`,
      },
    })
    .then(response => response.json())
    .then((res) => {
      return `${base}?alt=media&token=${res.downloadTokens}`;
    })
    .then((url) => {  
      return fetch(url, {
        headers: {
          Accept: 'application/json',
        },
        method: 'GET',
      })
      .then(res => res.text())
      .then(contents => {
        return contents;
      })
      .catch((oops) => {
        console.log('here', oops)
      })
    })
    .catch(error => {
      console.error(error)
    })
  
  }

}

module.exports = storage;

function _global() {
  try {
     return !!window && window || global;
   } catch (e) {
     return global;
   }
}
