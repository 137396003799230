
const Components = require('components');

const components_resume_skill = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    const percentage = state.props.percentage || 80;
    const name = state.props.name || 'TODO';
    const last = !!state.props.last;

    return (
      <div style={{width: '100%', height: '125px' }}>
        <div style={{width: '100%', position: 'relative', left: '-20px' }}>
          <h1 style={{color: 'white', marginLeft: '30px', fontSize: '50px', fontWeight: 100, position: 'absolute', top: '0px', left: '85px'}} >
            { name }
          </h1>
          <div style={{backgroundColor: '#2d3e50', width: '25px', height: '25px', borderRadius: '50px', position: 'absolute', left: '62px', top: '35px'}} />
          { !last &&
            <div style={{backgroundColor: '#6885a5', width: '6px', height: '77px', position: 'absolute', left: '70px', top: '70px'}} />
          }

          <div style={{width: '35%', position: 'absolute', right: '40px', top: '33px'}}>
            <div style={{width: '100%', borderRadius: '20px', height: '30px', borderStyle: 'solid', borderColor: 'white', borderWidth: '5px'}} />
            <div style={{height: '93%', position: 10, position: 'absolute', top: '1px', backgroundColor: 'white', width: `${percentage}%`, borderRadius: '20px 15px 15px 20px'}} />
          </div>
        </div>
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_resume_skill }, require('./index.scss'));
