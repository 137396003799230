
const Components = require('components');

const components_apps_robo = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <div className="components_apps_robo">
        <Components.layouts.main />
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_apps_robo }, require('./index.scss'));
