
const Components = require('components');

const components_routes_settings = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <div className="components_routes_settings">
        <Components.misc.placeholder text='components_routes_settings' />
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_routes_settings }, require('./index.scss'));
