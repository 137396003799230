const defaultState = {};

const reducer = {
  set: (state, data) => {
    return data;
  },
}

const actions = (state, dispatch, Stores) => {

  function set() {
    dispatch('set')();
  }

  return {
    set,
  };

};

module.exports = require('wrappers/store')({ actions, reducer, namespace: 'stores_app', defaultState });
