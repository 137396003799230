const React = require('react');
const ReactDOM = require('react-dom');
const ReactRedux = require('react-redux');
const Redux = require('redux');
const Thunk = require('redux-thunk');
const errorStackParser = require('error-stack-parser');
require('@babel/polyfill');

module.exports = (options = {}) => {
  const { src, name, title } = options;

  window.APPLICATIONSTART = (state) => {

    const { entry, config } = src.apps['robo']();
    const Comps = { entry };

    _setupGlobalErrorHandler();
    
    const { actions, store, selectors } = initStore(src.stores, { ...state, app: { data: { ...config, cert: window.APPLICATIONCERT } } });

    window.APPLICATIONACTIONS = actions;
    window.APPLICATIONSTORE = store;
    window.APPLICATIONSELECTORS = selectors;

    document.title = title || name;

    return new Promise((resolve) => {
      ReactDOM.render(
        <>
          <ReactRedux.Provider store={store}>
            <Comps.entry />
          </ReactRedux.Provider>
        </>
      ,
      document.getElementById('appContainer'),
      resolve)
    });

  };

  window.APPLICATIONSTATE = () => {
    return window.APPLICATIONSTORE.getState() || {};
  }

  function initStore(stores, state) {

    const store = Redux.createStore(composeReducersFromStores(stores), state, Redux.compose(Redux.applyMiddleware(Thunk.default), Redux.applyMiddleware(batchDispatchMiddleware)));
    const actions = composeActionsFromStores(stores);
    const selectors = src.selectors;

    return { store, actions, selectors };

    function composeReducersFromStores(stores = {}) {
      return Redux.combineReducers(
        Object.keys(stores || {})
          .reduce((acc, key) => {
            const val = stores[key];
            if (key !== 'reducer' && key !== 'actions' && key.indexOf('__') !== 0) {
              acc[key] = composeReducersFromStores(val)
            }
            return acc;
          }, stores.reducer || {}))
    }

    function composeActionsFromStores(stores = {}) {
      return Object.keys(stores)
      .reduce((acc, key) => {
        if (key !== 'reducer' && key !== 'actions') {
          acc[key] = composeActionsFromStores(stores[key])
        }
        return acc;
      }, stores.actions || {})
    }

  }

  // Global helpers
  window._resolve = (object, itemsString, fallback) => {
    if (object === undefined || object === null) return fallback;
    if (!(itemsString || '').length) return object;
    const items = itemsString.split('.');
    return window._resolve(object[items[0]], items.slice(1, 10).join('.'), fallback);
  };

  window.timePatching = 0;
  window._patch = (state, patch = {}) => {
    const start = Date.now();
    if (state === patch) {
      return state;
    }
    if (typeof patch !== 'object') {
      window.timePatching += Date.now() - start;
      return patch;
    }

    let hasNew = false;
    const keysToDelete = [];
    const toSpread = (Object.keys(patch || {})
      .reduce((acc, key) => {
        if (patch[key] !== null) {
          acc[key] = window._patch((state[key] || {}), patch[key]);
        } else {
          keysToDelete.push(key);
        }
        if (acc[key] !== state[key]) hasNew = true;
        return acc;
      }, {}));

    if (toSpread === state || !hasNew) {
      return state;
    }

    const toReturn = {
      ...(state || {}),
      ...toSpread,
    };
    keysToDelete.forEach(key => delete toReturn[key]);

    return toReturn;
  }

  window._try = (toTry, onErrorReturn) => {
    try {
      var toReturn = toTry();
      if (toReturn === undefined) return onErrorReturn;
      return toReturn;
    } catch (e) {
      return onErrorReturn;
    }
  }

  window._wait = (time) => {
    return (val) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(val);
        }, time || 0)
      });
    };
  }

  window._c = console.log;

  function _setupGlobalErrorHandler() {

    if (window._catchError) return;

    Error.stackTraceLimit = 50;
    let sendingError;

    window._catchError = (message, x2, x3, x4, error) => {

      if (message && message.apiError) {
        alert(`Uncaught API Error ${JSON.stringify(message)}`)
        return;
      }

      const tosend = {
        message,
        stack: [],
      };

      try {
        tosend.stack = errorStackParser.parse(error)
          .map((item) => {
            return {
              column: item.columnNumber,
              line: item.lineNumber,
              file: item.fileName.split(`${window.location.origin}/`)[1],
            };
          })
      } catch (e) {
        console.log(message)
        console.log("-- error not parsable")
        // console.log(e)
        // console.log('error not parsable');
      }

      const encoded = btoa(JSON.stringify(tosend));
      fetch(`?stack=${encoded}`)
        .then((res) => {
          sendingError = false;
          return res.json && res.json() || Promise.resolve({});
        })

    };
    window.onerror = window._catchError;
    window.addEventListener("unhandledrejection", window._catchError);
  }

  function batchDispatchMiddleware(store) {
    function dispatchChildActions(store, action) {
      if(action.meta && action.meta.batch) {
        const start = Date.now();
        window.patchingshit = true;
        const timePatchingStart = window.timePatching;
        action.payload.forEach((childAction, index) => {
          if (index + 1 === action.payload.length) { window.patchingshit = false }
          dispatchChildActions(store, childAction)
        })
      } else {
        window.APPLICATIONSTORE.dispatch(action)
      }
    }

    return function(next) {
      return function(action) {
        if (action && action.meta && action.meta.batch) {
          dispatchChildActions(store, action)
        }
        return next(action)
      }
    }
  }
}
