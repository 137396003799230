
const Components = require('components');

const components_misc_portfoliocard = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <div className="components_misc_portfoliocard">
        <div className="toppart">
          <Components.misc.img className="w-100" src={state.props.img} />
          <div className="robo-abs">
            <div className="overlayshow robo-rel robo-cursor-p">
              <div className="overlay robo-rel fade-in in">
                <a className="btn btn-dc btn-white robo-center" href={state.props.href} target="__blank">View</a>
              </div>
            </div>
          </div>
        </div>
        <div className="bottompart">
          <div className="w-100 robo-center">
            <h2 className="mb-0 text-center nomargin">{state.props.title}</h2>
            <p className="text-center nomargin">{state.props.desc}</p>
          </div>
        </div>
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_misc_portfoliocard }, require('./index.scss'));
