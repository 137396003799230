const storage = require('./utils/storage');
const db = require('./utils/db');
const id = require('./utils/id');

// POLYFILLS
(_global()).WebSocket = (_global()).WebSocket || require('ws');
(_global()).fetch = (_global()).fetch || require('node-fetch');

(_global()).PERSIST_SESSION = (_global()).PERSIST_SESSION || {};

function persist(namespace = 'default') {

  (_global()).PERSIST_SESSION[namespace] = (_global()).PERSIST_SESSION[namespace] || {};

  const INTERNAL = (_global()).PERSIST_SESSION[namespace];

  function init(creds = {}) {

    INTERNAL._db = db(namespace);
    if (creds.db) {
      INTERNAL._db.init({
        db: creds.db,
        ns: creds.ns,
        auth: creds.auth,
      })
    }

    INTERNAL._storage = storage(namespace);
    if (creds.bucket) {
      INTERNAL._storage.init({
        bucket: creds.bucket,
        auth: creds.auth,
      })
    }

    return Promise.all([
      creds.db && INTERNAL._db.connect && INTERNAL._db.connect() || Promise.resolve(),
      creds.bucket && INTERNAL._storage.connect && INTERNAL._storage.connect() || Promise.resolve(),
    ])

  }

  return {
    // INITALIZE
    init,

    // ID

    // DB
    set: (...args) => INTERNAL._db.set(...args),
    patch: (...args) => INTERNAL._db.patch(...args),
    retrieve: (...args) => INTERNAL._db.retrieve(...args),
    watch: (...args) => INTERNAL._db.watch(...args),
    unwatch: (...args) => INTERNAL._db.unwatch(...args),
    wait: (...args) => INTERNAL._db.wait(...args),

    // STORAGE
    upload: (...args) => INTERNAL._storage.upload(...args),
    download: (...args) => INTERNAL._storage.download(...args),

    id,
  }

}

module.exports = persist;

function _global() {
  try {
     return !!window && window || global;
   } catch (e) {
     return global;
   }
}
