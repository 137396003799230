
const Components = require('components');

const components_resume_positionOverview = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    const organization = state.props.organization; 
    const startDate = state.props.startDate;
    const endDate = state.props.endDate;
    const position = state.props.position;
    const description =  state.props.description;

    return (
      < >
        <h1 style={{color: '#344f6b', fontSize: '60px', fontWeight: 400, float: 'left', margin: '0px'}}>
          <span style={{position: 'relative', left: '35px'}}>
            <span style={{whiteSpace: 'nowrap'}} no-line-breaks="true" strip-br="true">{organization}</span>
            <span style={{position: 'relative', top: '-3px', fontSize: '45px', fontWeight: 500, color: '#6a6a6a'}}>&nbsp;/&nbsp;</span>
            <span no-line-breaks="true" strip-br="true" style={{position: 'relative', top: '0px', fontSize: '45px', fontWeight: 500, color: '#6a6a6a'}}>{startDate}</span>
            <span style={{position: 'relative', top: '0px', fontSize: '45px', fontWeight: 500, color: '#6a6a6a'}}>&nbsp;-&nbsp;</span>
            <span no-line-breaks="true" strip-br="true" style={{position: 'relative', top: '0px', fontSize: '45px', fontWeight: 500, color: '#6a6a6a'}}>{endDate}</span>
          </span>
          <span style={{position: 'relative', backgroundColor: '#2d3e50', width: '26px', height: '26px', borderRadius: '50px', top: '22px', float: 'left'}} />
        </h1>
        <div style={{clear: 'both'}} />
        <div style={{borderWidth: '0px 0px 0px 8px', borderStyle: 'solid', borderColor: '#dcdbdb', position: 'relative', top: '2px', left: '9px', padding: '20px 0px 17px 20px'}}>
          <p style={{marginLeft: '24px', position: 'relative', top: '2px', wordWrap: 'break-word', whiteSpace: '-o-pre-wrap'}}>
            <span no-line-breaks="true" strip-br="true" style={{color: 'black', fontSize: '45px'}} >{position}</span>
            <span style={{color: 'black', fontSize: '45px'}}>: &nbsp;</span>
            <span no-line-breaks="true" strip-br="true" style={{color: '#6a6a6a', whiteSpace: 'pre', fontSize: '45px', fontWeight: 300, borderStyle: 'solid', borderWidth: '3px 0px 3px 0px', borderColor: 'white', display: 'block'}} >{description}</span>
          </p>
        </div>
      </>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_resume_positionOverview }, require('./index.scss'));
