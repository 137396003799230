
const Components = require('components');

const components_misc_img = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <img
        className={`components_misc_img ${state.props.className}`}
        style={{ ...(state.props.style || {}) }}
        src={`/src/assets/${state.props.src}`}  />
    );
  },

};

module.exports = require('src/wrappers/component')({ components_misc_img }, require('./index.scss'));
