const createSelector = require('reselect/dist/reselect').createSelector;

const debug = '';

function WrappedSelector(Selector) {

  const selectorName = Object.keys(Selector)[0];
  const { mapStore, select } = Selector[selectorName];

  const names = Object.keys(mapStore({}, {}, ''));
  log('created')

  const selector = createSelector(
    ...(names
      .map((name) => {
        return (state) => {
          const item = mapStore(window.APPLICATIONSTATE(), window.APPLICATIONSELECTORS, state.__namespace)[name];
          return _try(() => item[0](), item[1]);
        }
      })
    ),
    state => state.__namespace,
    (...args) => {
      const store = args
        .reduce((acc, curr, index) => {
          acc[names[index] || '__namespace'] = curr
          return acc;
        }, {})
      return select(store)
    }
  );

  return (namespace) => {
    return selector({ __namespace: namespace });
  }

  function log(...args){
    if (debug !== selectorName) return;
    console.log('selector: ', ...args);
  }
}

module.exports = WrappedSelector;

const _try = (toTry, onErrorReturn) => {
  try {
    const toReturn = toTry();
    if (toReturn === undefined) return onErrorReturn;
    return toReturn;
  } catch (e) {
    return onErrorReturn;
  }
}
