const Utils = require('src/utils');

function utils_scrollto(name) {
  const el = document.getElementById(name)
  if(el) {
    setTimeout(() => {
      const offset = document.getElementById('main-content').offsetTop;
      document.getElementById('page-contents').scroll({ top: el && (el.offsetTop + offset) || 0, behavior: 'smooth' })
    });
  }

}

module.exports = utils_scrollto;
