
const Components = require('components');

const components_resume_right = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <div style={{width: '90%', marginLeft: 'auto', marginRight: 'auto'}} className="components_resume_right">
        <Components.resume.categorytitle 
          title="Work Experience"
          icon="fa-briefcase"
        />
        <div style={{width: '100%', marginLeft: '60px', marginTop: '60px', paddingRight: '80px', marginBottom: '100px'}}>
           <Components.resume.positionOverview
            organization={"Contractor"}
            startDate={'January 2021'}
            endDate={'current'}
            position={'Full Stack Consultant'}
            description={`- Serverless 
- Google Cloud Platform 
- Node JS / TypeScript
- Kubernettes / Docker 
- React, Redux 
- RESTful APIs
- Solidity / Truffle / Web3

`}
          />
          <Components.resume.positionOverview
            organization={"PayClearly"}
            startDate={'December 2017'}
            endDate={'January 2021'}
            position={'VP Engineering'}
            description={`Led the development of PayClearly's core accounts payable
platform, form zero lines of code to profitability.

- Built development team
- Worked with product to define specifications
- Designed and implemented serverless architecture
- GPC | Cloud Functions | React/Redux
  | Firebase | MySQL | Github

`}
          />
          <Components.resume.positionOverview
            organization={"Nike"}
            startDate={'August 2015'}
            endDate={'October 2017'}
            position={'Lead Software Developer'}
            description={`Led the development of nike.com/launch

- Javascript, HTML, CSS
- ES6 with Babel
- Mocha testing framework
- Reactjs / React
- NodeJS
- Gulp
- E2E testing using Appium and Selenium
- Agile methodology

`}
          />
<Components.resume.positionOverview
            organization={"Minapsys"}
            startDate={'June 2012'}
            endDate={'March 2015'}
            position={'Lead Software Developer'}
            description={`Led the development of Minapsys' core service from zero lines
of code to a revenue producing product.

- Implemented items from Feature Requests to Done
- Created and documented API Calls
- Implemented design and AngularJS integration
- Developed and managed NodeJS Admin Application
- Managed application testing
- Designed core architecture
- Developed and Integrated Application Usage Analytics
- Hired UI/UX and Backend Engineers
- Managed IT Policy in accordance with blue chip company
security requirements

`}
        />
        </div>
        <div style={{width: '100%', height: 'auto', marginLeft: '0px', marginTop: '60px', paddingRight: '80px', marginBottom: '100px'}}>
          <div style={{width: '50%', float: 'right'}}>
            <div skills-list="skills-list" skills="leftSkillsMainOne" type={2} />
          </div>
          <div style={{width: '50%'}}>
            <div skills-list="skills-list" skills="leftSkillsMainTwo" type={2} />
          </div>
        </div>
        <Components.resume.categorytitle 
          title="Education"
          icon="fa-graduation-cap"
        />
          <div style={{width: '100%', marginLeft: '60px', marginTop: '60px', paddingRight: '80px', marginBottom: '100px'}}>
<Components.resume.positionOverview
            organization={"Washington State University"}
            startDate={'2004'}
            endDate={'2009'}
            position={'Bachelor\'s degree - Physics'}
            description={``}
       />
         </div>
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_resume_right }, require('./index.scss'));
