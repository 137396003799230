
const Components = require('components');

const components_sections_about = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <section id="about" className="components_sections_about">
        <div className="container">
          <div className="row profile">
            <div className="col-sm-10 col-sm-offset-1 text-center">
              <p className="margin-top scrollimation fade-right in">I've been working with computers for a very long time. For the last ten years, I've been working on web stack applications. If you have any projects that you would like to discuss, please let me know!</p>              
              <p className="lead margin-top scrollimation fade-left in"></p>
            </div>
          </div>
        </div>
      </section>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_sections_about }, require('./index.scss'));
