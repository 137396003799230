
const Components = require('components');

const components_sections_services = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <section id="services" className="big-padding">
        
          <div className="container">
            
            <h3 className="section-title">Full Stack</h3>
            
            <div className="text-center">
              <div className="btn-group">
                <button type="button" href="#front-end" className="btn btn-default toggle-tabs active">Front End</button>
                <button type="button" href="#back-end" className="btn btn-default toggle-tabs">Back End</button>
              </div>
            </div>
            
            <div className="tab-content">
            
              <div className="row tab-pane active" id="front-end">
              
                <div className="col-sm-4 service-item scrollimation fade-left in">
                
                  <div className="service-icon "><i className="fa fa-html5"></i></div>
                  
                  <h3>Clean HTML5 Code</h3>
                  
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo.</p>
                  
                  
                </div>
              
                <div className="col-sm-4 service-item scrollimation fade-left in">
                
                  <div className="service-icon "><i className="fa fa-css3"></i></div>
                  
                  <h3>Modern CSS3 styles</h3>
                  
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo.</p>
                  
                  
                </div>
                
                <div className="col-sm-4 service-item scrollimation fade-left in">
                
                  <div className="service-icon "><i className="fa fa-mobile"></i></div>
                  
                  <h3>Responsive Design</h3>
                  
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo.</p>
                  
                </div>
                
              </div>
              
              <div className="row tab-pane" id="back-end">
              
                <div className="col-sm-4 service-item">
                
                  <div className="service-icon"><i className="fa fa-wordpress"></i></div>
                  
                  <h3>Wordpress</h3>
                  
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo.</p>
                  
                  
                </div>
              
                <div className="col-sm-4 service-item">
                
                  <div className="service-icon"><i className="fa fa-joomla"></i></div>
                  
                  <h3>Joomla</h3>
                  
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo.</p>
                  
                  
                </div>
                
                <div className="col-sm-4 service-item">
                
                  <div className="service-icon"><i className="fa fa-drupal"></i></div>
                  
                  <h3>Drupal</h3>
                  
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo.</p>
                  
                </div>
                
              </div>
              
            </div>
            
          </div>
        
        
        </section>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_sections_services }, require('./index.scss'));
