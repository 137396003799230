
const Components = require('components');

const components_resume_categorytitle = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    const title = state.props.title || 'TODO';
    const icon = state.props.icon || '';
    return (
      <div style={{width: '100%'}}>
        <div ac-spacer="ac-spacer" editable="editable" height="topMargin" />
        <div style={{width: '100%', height: '150px', position: 'relative'}}>
          <div style={{height: '140px', width: '140px', backgroundColor: '#344f6b', borderRadius: '75px'}}>
            <h1 style={{color: 'white', fontSize: '80px', verticalAlign: 'middle', margin: '0px', position: 'relative', top: '28px'}} className="text-center"><i className={`fa ${icon}`} /></h1>
          </div>
          <div style={{height: '6px', width: '85%', backgroundColor: '#344f6b', position: 'absolute', right: '0px', top: '70px', borderRadius: '75px'}} />
          <div style={{height: '120px', backgroundColor: 'white', position: 'absolute', left: '160px', top: '15px', marginBottom: '22px', marginLeft: '20px'}}>
            <h2 style={{color: '#344f6b', fontSize: '65px', fontWeight: 400, maxWidth: '1100px'}}>
              <span style={{whiteSpace: 'nowrap'}} collapse-spaces="true" no-line-breaks="true" select-non-editable="true" strip-br="true" ng-model="title">{title}</span>
              <span>&nbsp;&nbsp;</span>
            </h2>
          </div>
        </div>
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_resume_categorytitle }, require('./index.scss'));
