
const Components = require('components');

require('assets/images/me.jpg')

const components_resume_left = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      // setView: (data) => actions.setView(data)
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {}, // default view state

  render: (state, handlers) => {
    return (
      <div className="components_resume_left">
        <h1 style={{color: 'white'}} className="text-center" />
          <div style={{backgroundColor: 'white', width: '500px', height: '500px', margin: '70px auto 0px auto', borderRadius: '1000px', position: 'relative', overflow: 'hidden'}}>
            <Components.misc.img src="images/me.jpg" style={{height: '700px', position: 'relative', top: '-60px'}} />
          </div>
          <h1 style={{color: 'white', marginTop: '40px', fontSize: '100px', fontWeight: 400}} className="text-center">Aaron Colby</h1>
          <h1 style={{color: 'white', marginTop: '30px', fontSize: '70px', fontWeight: 300}} className="text-center">Software Engineer</h1>
          <h1 style={{color: 'white', marginTop: '50px', fontSize: '50px', fontWeight: 300}} className="text-center"> <span><i className="fa fa-map-marker" />&nbsp;</span><span>Portland, OR</span></h1>
          <div style={{width: '100%', marginTop: '70px', backgroundColor: '#637d9a', height: '160px'}}>
            <h1 style={{color: 'white', paddingTop: '45px', marginLeft: '30px', fontSize: '70px', fontWeight: 300}} className="text-center">253.307.8512</h1>
          </div>
          <div style={{width: '100%', marginTop: '70px'}} />
          <h1 style={{color: 'white', marginTop: '30px', marginLeft: '30px', fontSize: '70px', fontWeight: 400}}>About Me</h1>
          <p style={{color: 'white', marginTop: '80px', marginLeft: '50px', marginRight: '50px', fontSize: '40px', fontWeight: 400}}>I love turning concepts into well working, easy to use applications.  I'm a full stack web programer with lots of experience.</p>
          <p style={{color: 'white', marginTop: '30px', marginLeft: '50px', marginRight: '50px', fontSize: '40px', fontWeight: 400}}>Thanks for checking out my resume, and please don't hesitate to contact me if you have any questions.</p>
          
          <h1 style={{color: 'white', marginTop: '120px', marginLeft: '30px', marginBottom: '100px', fontSize: '70px', fontWeight: 400}}>Professional Skills</h1>

          <Components.resume.skill 
            name="FRONTEND"
            percentage={85}
          />
          <Components.resume.skill 
            name="BACKEND"
            percentage={90}
          />
          <Components.resume.skill 
            name="BLOCKCHAIN"
            percentage={80}
          />
          <Components.resume.skill 
            name="COMUNICATION"
            percentage={90}
          />
          <Components.resume.skill 
            name="TEAMWORK"
            percentage={95}
            last={true}
          />


          <h2 style={{color: 'white', marginTop: '140px', marginLeft: '30px', marginBottom: '20px', fontSize: '40px', fontWeight: 400}}> <span style={{color: '#007ab8', fontSize: '50px'}}><i className="fa fa-envelope" />&nbsp; &nbsp;</span><span>acolby@live.com</span></h2>
          <h2 style={{color: 'white', marginTop: '22px', marginLeft: '30px', marginBottom: '22px', fontSize: '40px', fontWeight: 400}}> <span style={{color: '#00b6d4', fontSize: '50px'}}><i className="fa fa-server" />&nbsp; &nbsp;</span><span>www.acolby.com</span></h2>
          <h2 style={{color: 'white', marginTop: '22px', marginLeft: '30px', marginBottom: '22px', fontSize: '40px', fontWeight: 400}}> <span style={{color: '#d74e86', fontSize: '50px'}}><i className="fa fa-github-square" />&nbsp; &nbsp;</span><span>https://github.com/acolby</span></h2>
          <h2 style={{color: 'white', marginTop: '22px', marginLeft: '30px', marginBottom: '22px', fontSize: '40px', fontWeight: 400}}> <span style={{color: '#00b6d4', fontSize: '50px'}}><i className="fa fa-phone" />&nbsp; &nbsp;</span><span>253.307.8512</span></h2>
          <h2 style={{color: 'white', marginTop: '22px', marginLeft: '30px', marginBottom: '22px', fontSize: '40px', fontWeight: 400}}> <span style={{color: '#007ab8', fontSize: '50px'}}><i className="fa fa-linkedin-square" />&nbsp; &nbsp;</span><span>www.linkedin.com/in/aaronacolby</span></h2>
      </div>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_resume_left }, require('./index.scss'));
