const defaultState = {
  route: '',
  modal: null,
  query: '',
};

const reducer = {
  setRoute: (state, data) => {
    return { route: data };
  },
  setModal: (state, data) => {
    return { modal: data };
  },
  closeModal: (state, data) => {
    return { modal: null };
  },
}

const actions = (state, dispatch, Stores) => {

  function setRoute(data) {
    dispatch('setRoute')(data);
  }

  function setModal(data) {
    dispatch('setModal')(data);
  }

  function closeModal(data) {
    dispatch('closeModal')(data);
  }

  return {
    setRoute,
    setModal,
    closeModal,
  };

};

module.exports = require('../../wrappers/store')({ actions, reducer, namespace: 'stores_router', defaultState });