
const Components = require('components');

const components_sections_contact = {

  //  { item: [() => Stores.store[data || action], 'default'] },
  mapActions: (Stores) => {
    return {};
  },
  mapStore: (Stores, Selectors) => {
    return {}; 
  },

  handlers: (state, actions) => {
    return {
      setView: (data) => actions.setView(data),
      onSubmit: (data) => {
        if (state.view.submitting) return;
        Promise.resolve()
        .then(() => {
          actions.setView({ submitted: false, submitting: true });
          return new Promise(resolve => setTimeout(resolve, 2000))
        })
        .then(() => {
          actions.setView({
            submitted: true,
            submitting: false,
            val_name: '',
            val_email: '',
            val_message: ''
          });
        })
       
      }
    };
  },

  componentMounted: (state, handlers) => {
    return {};
  },
  componentDerive: (state, handlers) => {
    return {};
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {
    val_name: '',
    val_email: '',
    val_message: '',
  }, // default view state

  render: (state, handlers) => {
    return (
      <section id="contact" className="big-padding light-typo color-overlay components_sections_contact">
      
        <div className="container">
        
          <h1 className="section-title">Contact<span className="main-color"></span></h1>
          
          <div className="row">
          
            <div className="col-sm-6 col-md-5 scrollimation fade-right in">

              <p className="lead">I am available for project work or hire. <br/>Can't wait for another exciting project to work on!</p>

              <p>
                Aaron Colby<br/>
                Portland, OR<br/>
                (253) 307 - 8512 <br/>
              </p>

              <div className="social-links">
                <a href="https://github.com/acolby" target="_blank"><i className="fa fa-fw fa-github"></i></a>                
                <a href="http://stackoverflow.com/users/2296294/aaron" target="_blank"><i className="fa fa-fw fa-stack-overflow"></i></a>                
                <a href="https://www.facebook.com/aaron.a.colby" target="_blank"><i className="fa fa-fw fa-facebook-square"></i></a>                
                <a href="https://www.linkedin.com/hp/?dnr=o44LZAvjoPM5jtWXCuy2tNId7PPb4NpP2IgS&amp;trk=nav_responsive_tab_home" target="_blank"><i className="fa fa-fw fa-linkedin-square"></i></a>
              </div>

              <p><a className="mail-styled" href="mailto:mail@example.com">acolby@live.com</a></p>
            
            </div>
          
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handlers.onSubmit();
              }}
              id="contact-form"
              className="col-sm-6 col-md-offset-1 scrollimation fade-left in"
              action="contact.php"
              method="post"
              noValidate=""
            >
              
              <div className="form-group">
                <div className="controls" data-children-count="1">
                  <input className={`form-control requiredField ${(state.view.val_name || '').length && 'input-filled'}`} type="text" data-error-empty="is required" id="contact-name" name="contactName"
                    onChange={(e) => { handlers.setView({ val_name: e.target.value }) }}
                    value={state.view.val_name || ''}
                  />
                  <label className="control-label" htmlFor="contact-name">Name</label>
                </div>
              </div>
              
              <div className="form-group">
                <div className="controls" data-children-count="1">
                  <input id="contact-mail" name="email" className={`form-control requiredField ${(state.view.val_email || '').length && 'input-filled'}`} type="email" data-error-empty="is required" data-error-invalid="is invalid"
                    onChange={(e) => { handlers.setView({ val_email: e.target.value }) }}
                    value={state.view.val_email || ''}
                  />
                  <label className="control-label" htmlFor="contact-mail">Email</label>
                </div>
              </div>
              
              <div className="form-group">
                <div className="controls" data-children-count="1">
                  <textarea id="contact-message" name="comments" className="form-control requiredField" rows="6" data-error-empty="is required"
                    className={`form-control requiredField ${(state.view.val_message || '').length && 'input-filled'}`}
                    onChange={(e) => { handlers.setView({ val_message: e.target.value }) }}
                    value={state.view.val_message || ''}
                  ></textarea>
                  <label className="control-label" htmlFor="contact-message">Message</label>
                </div>
              </div>
              
              <button name="submit" className="btn">
                Send It
              </button>
              <input type="hidden" name="submitted" id="submitted" value="true" />
              { state.view.submitting
                &&
                <p className="contact-form-message">Submitting....</p>
              }
              { state.view.submitted
                &&
                <p className="contact-form-message">Thank You! Your Message has been sent!</p>
              }
            </form>

          </div>

        </div>
      
      </section>
    );
  },

};

module.exports = require('src/wrappers/component')({ components_sections_contact }, require('./index.scss'));
