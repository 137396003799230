// nested by [compName][compId]
const defaultState = {};

const reducer = {
  set: (state, data) => {
    return data;
  },
};

const actions = (state, dispatch, Stores) => {

  function set(name, key, data) {
    dispatch('set')({ [name]: { [key]: { ...data } } });
  }

  function clear(name, key) {
    dispatch('set')({ [name]: { [key]: null } });
  }

  return {
    set,
    clear,
  };

};

module.exports = require('../../wrappers/store')({ actions, reducer, namespace: 'stores_view', defaultState });
