
const Components = require('components');
const src = require('./index.svg');

const svgs_misc_handout = {

  handlers: (state, actions) => {
    return {
      setView: (data) => actions.setView(data)
    };
  },

  remountonservidor: true,

  componentMounted: (state, handlers) => {
    fetch(src.default)
    .then(res => res.text())
    .then(contents => handlers.setView({ contents }))
    return {};
  },
  componentDerive: (state, handlers) => {
    return {
      contents: state.view.contents,
    };
  },
  componentUnmounted: (state, handlers) => {
    return {};
  },

  view: {
    contents: '',
  },

  render: (state, handlers) => {
    return (
      <div
        className={`svgs_misc_handout ${state.props.className || ''}`}
        style={{ ...(state.props.style || {}) }}
        dangerouslySetInnerHTML={{ __html: state.derived.contents || '<svg></svg>' }}
      />
    );
  },

};

module.exports = require('src/wrappers/component')({ svgs_misc_handout }, require('./index.scss'));
