
function importar(options = {}) {
  const Module = _tryRequire('module');
  if (!Module) return;

  const include = Module.prototype.require;
  
  const basePath = options.basePath || process.cwd();
  const prefix = options.prefix || '/';
  Module.prototype.require = function(path) {
    let toRequire = path;
    if (path.indexOf(basePath) !== 0 && path.indexOf(prefix) === 0) toRequire = `${basePath}/${path}`.replace('//', '/');
    return include.call(this, `${toRequire}`);
  };
}

module.exports = importar;

function _tryRequire(path) {
  try {
    return require(path);
  } catch (e) {
    return undefined;
  }
}
